"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_bootstrap_1 = require("react-bootstrap");
function TimeSlotSelector({ timeSlots }) {
    const [selectedDate, setSelectedDate] = (0, react_1.useState)(''); // État pour la date sélectionnée
    const [selectedTimeSlot, setSelectedTimeSlot] = (0, react_1.useState)(''); // État pour le créneau sélectionné
    const availableDates = Object.keys(timeSlots);
    // Initialisation : si une seule date est disponible, la sélectionner automatiquement
    (0, react_1.useEffect)(() => {
        if (availableDates.length === 1) {
            setSelectedDate(availableDates[0]);
        }
    }, [availableDates]);
    // Fonction pour formater la date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };
    // Fonction pour formater l'heure
    const formatTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
    };
    // Gestion de la sélection du jour
    const handleDateChange = (event) => {
        const selectedDay = event.target.value;
        setSelectedDate(selectedDay);
        setSelectedTimeSlot(''); // Réinitialiser le créneau sélectionné
    };
    // Gestion de la sélection du créneau
    // Gestion du changement de sélection
    const handleTimeSlotChange = (event) => {
        const endTime = event.target.value;
        setSelectedTimeSlot(endTime);
        if (endTime === '') {
            return;
        }
        // Extraire les créneaux horaires correspondants
        const slots = timeSlots[selectedDate].find((slot) => slot[1].date === endTime);
        if (slots) {
            console.log(slots);
        }
    };
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(react_bootstrap_1.Form.Group, { controlId: "dateSelect" },
            react_1.default.createElement(react_bootstrap_1.Form.Label, null, "Pour pour le "),
            react_1.default.createElement(react_bootstrap_1.Form.Control, { as: "select", value: selectedDate, onChange: handleDateChange, disabled: availableDates.length === 1, required: true },
                react_1.default.createElement("option", { value: "" }, "Choisir un jour"),
                availableDates.map((dateKey) => (react_1.default.createElement("option", { key: dateKey, value: dateKey }, formatDate(dateKey)))))),
        selectedDate && (react_1.default.createElement(react_bootstrap_1.Form.Group, { controlId: "timeSlotSelect" },
            react_1.default.createElement(react_bootstrap_1.Form.Label, null, "\u00E0"),
            react_1.default.createElement(react_bootstrap_1.Form.Control, { as: "select", value: selectedTimeSlot, onChange: handleTimeSlotChange, required: true },
                react_1.default.createElement("option", { value: "" }, "Choisir un cr\u00E9neau"),
                timeSlots[selectedDate].map((slot) => {
                    const endTime = slot[1].date;
                    return (react_1.default.createElement("option", { key: endTime, value: endTime }, formatTime(endTime)));
                }))))));
}
exports.default = TimeSlotSelector;
