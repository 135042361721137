"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class CartItem {
    constructor(pizza, quantity) {
        this._pizza = pizza;
        this._quantity = quantity;
        (0, mobx_1.makeAutoObservable)(this);
    }
    get price() {
        return this.pizza.price * this.quantity;
    }
    get pizza() {
        return this._pizza;
    }
    set pizza(value) {
        this._pizza = value;
    }
    get quantity() {
        return this._quantity;
    }
    set quantity(value) {
        if (value < 0) {
            value = 0;
        }
        this._quantity = value;
    }
}
exports.default = CartItem;
