"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
// Définition du type pour les jours de la semaine
class OpeningHours {
    constructor({ day = null, active = false, openTime = '', closeTime = '', nextOpenDate = null, nextCloseDate = null, }) {
        this._day = day;
        this._active = active;
        this._openTime = openTime;
        this._closeTime = closeTime;
        this._nextOpenDate = nextOpenDate;
        this._nextCloseDate = nextCloseDate;
        (0, mobx_1.makeAutoObservable)(this);
    }
    // Méthode pour mettre à jour l'heure d'ouverture
    setOpenTime(hour, minute) {
        this.openTime = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
    }
    // Méthode pour mettre à jour l'heure de fermeture
    setCloseTime(hour, minute) {
        this.closeTime = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
    }
    dayToFrench() {
        const daysTranslation = {
            Monday: 'Lundi',
            Tuesday: 'Mardi',
            Wednesday: 'Mercredi',
            Thursday: 'Jeudi',
            Friday: 'Vendredi',
            Saturday: 'Samedi',
            Sunday: 'Dimanche',
        };
        return daysTranslation[this.day] || this.day;
    }
    toJson() {
        return {
            day: this._day,
            active: this._active,
            openTime: this._openTime,
            closeTime: this._closeTime,
        };
    }
    // Getter et setter pour day
    get day() {
        return this._day;
    }
    set day(value) {
        this._day = value;
    }
    // Getter et setter pour active
    get active() {
        return this._active;
    }
    set active(value) {
        this._active = value;
    }
    // Getter et setter pour openTime
    get openTime() {
        return this._openTime;
    }
    set openTime(value) {
        this._openTime = value;
    }
    // Getter et setter pour closeTime
    get closeTime() {
        return this._closeTime;
    }
    set closeTime(value) {
        this._closeTime = value;
    }
    // Getter et setter pour nextOpenDate
    get nextOpenDate() {
        return this._nextOpenDate;
    }
    set nextOpenDate(value) {
        this._nextOpenDate = value;
    }
    // Getter et setter pour nextCloseDate
    get nextCloseDate() {
        return this._nextCloseDate;
    }
    set nextCloseDate(value) {
        this._nextCloseDate = value;
    }
    // Fonction pour savoir si les horaires sont actifs
    isActive() {
        return this._active;
    }
}
exports.default = OpeningHours;
